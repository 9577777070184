import React, { ChangeEvent, useEffect, useState } from 'react';
import './Chat.page.css';
import '../../ui/Shared.css';
import { IoMdAdd, IoMdSend } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';
import InputField from '../../ui/components/InputField';
import DropDownMenu from '../../ui/components/DropDownMenu';
import NumberInputField from '../../ui/components/NumberInputField';
import SourceCard from '../../ui/components/SourceCard';
import LargeInputField from '../../ui/components/LargeInputField';
import { useNavigate } from 'react-router-dom';
import MainLogo from '../../ui/components/MainLogo';
import SidePanelScaffold from '../../ui/pageScaffolds/SidePanelScaffold';
import { FaRegMessage } from 'react-icons/fa6';
import { FaSync } from 'react-icons/fa';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import HoveringCard from '../../ui/components/HoveringCard';
import { LoginState } from '../../types/LoginState';
import BugButton from '../../ui/components/BugButton/BugButton';
import FeedBackButton from '../../ui/components/FeedBackButton/FeedBackButton';
import { Source } from '../../types/Source';

const isMock = process.env.REACT_APP_MOCK === 'true';

// interface Source {
//   videoTitle: string;
//   videoURL: string;
//   embedLink: string | null;
//   relevancy: string;
//   associatedStudies: {
//     studyTitle: string;
//     studyURL: string;
//     studyAbstract: string;
//     studyKeywords: string;
//   }[];
// }

interface ChatMessage {
  role: string;
  text: string;
}

interface TrimmedHistory {
  chatHistoryId: number;
  text: string;
}

interface ChatSetting {
  educationLevel: string;
  detailLevel: string;
  threshold: number;
}

interface ChatData {
  query: string;
  botResponse: string;
  chatMessages: ChatMessage[];
  chatId: number;
}

function ChatPage() {
  const token = localStorage.getItem('token'); // Retrieve the token
  const nav = useNavigate();
  const levels = ['Short', 'Medium', 'Long'];
  const [hide, setHide] = useState(true);
  const [loginState, setLoginState] = useState<LoginState>({
    isLoggedIn: false,
    isAdmin: false,
  });

  const validateUserLogin = async () => {
    if (token) {
      const url = '/validate';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      if (!response.ok) {
        localStorage.removeItem('token');
        nav('/');
        return;
      }
      const json = await response.json();

      if (json.valid && json.valid === true) {
        setLoginState({ isLoggedIn: true, isAdmin: true });
      } else {
        setLoginState({ isLoggedIn: true, isAdmin: false });
      }
    }
  };

  useEffect(() => {
    validateUserLogin();
  }, []);

  const [chatSettings, setChatSettings] = useState<ChatSetting>({
    educationLevel: 'A high school student',
    detailLevel: levels[0],
    threshold: 40,
  });

  const [chatData, setChatData] = useState<ChatData>({
    query: '',
    botResponse: '',
    chatMessages: [],
    chatId: -1,
  });

  const [sources, setSources] = useState<Source[]>([]);
  const [sourceError, setSourceError] = useState('');

  const [ws, setWs] = useState<WebSocket | null>(null);

  const [sideBarExpanded, setSideBarExpanded] = useState<boolean>(true);

  const [recentHistory, setRecentHistory] = useState<TrimmedHistory[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [historyError, setHistoryError] = useState(false);
  // TODO need to make a more robust method of selecting entities
  const [selectableEntities, setSelectableEntities] = useState<string[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChatData((prev) => ({
      ...prev,
      query: event.target.value,
    }));
  };

  const handleEducationLevelChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChatSettings({
      educationLevel: event.target.value,
      detailLevel: chatSettings.detailLevel,
      threshold: chatSettings.threshold,
    });
  };

  const handleThresholdChange = (value: number) => {
    setChatSettings({
      educationLevel: chatSettings.educationLevel,
      detailLevel: chatSettings.detailLevel,
      threshold: value,
    });
  };

  const handleDetailChange = (value: string) => {
    setChatSettings({
      educationLevel: chatSettings.educationLevel,
      detailLevel: value,
      threshold: chatSettings.threshold,
    });
  };

  const toggleSideBarExpansion = () => {
    setSideBarExpanded(!sideBarExpanded);
  };

  const getInitialEntities = async () => {
    const url = '/validentities';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error(`Network response was not ok`);
        // TODO should set a variable here
      }
      const json = await response.json();
      const entities: string[] = json;
      setSelectableEntities(entities);
      setSelectedEntities(entities);
    } catch (error) {
      // TODO should set a variable here
    }
  };

  const startWebSocket = (callback?: () => void) => {
    // Close the previous WebSocket if it's already open
    const mockEndpoint = isMock ? '/mock' : '';

    if (ws) {
      ws.close();
      setWs(null);
    }

    // Initialize the WebSocket connection
    let socket: WebSocket;
    if (process.env.REACT_APP_ENV === 'local') {
      socket = new WebSocket(`ws://localhost:3000/chatbot${mockEndpoint}`);
    } else {
      socket = new WebSocket(
        `${window.location.origin.replace(/^http/, 'ws')}/chatbot${mockEndpoint}`
      );
    }

    // Set the WebSocket on open connection and call the callback if provided
    socket.onopen = () => {
      if (token) {
        socket.send(JSON.stringify({ type: 'auth', token })); // Send the token as the first message
      }
      setWs(socket);
      if (callback) {
        callback(); // Send query only when WebSocket is open
      }
    };

    // Handle incoming messages
    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case 'chatId':
          setChatData((prev) => ({
            ...prev,
            chatId: data.id!,
          }));
          // setChatId(data.id!); // Assume chatId is number
          break;
        case 'aiResponseChunk':
          const newTextChunk: string = data.text;
          setChatData((prev) => ({
            ...prev,
            botResponse: prev.botResponse + newTextChunk,
          }));
          break;
        case 'messageEnd':
          setChatData((prev) => ({
            ...prev,
            botResponse: '',
            chatMessages: [
              ...prev.chatMessages, // Copy the existing messages
              { role: 'system', text: prev.botResponse }, // Append the new message
            ],
          }));
          break;
        case 'noSources':
          console.log(`Got no Sources`);
          setChatData((prev) => ({
            ...prev,
            botResponse: data.text ? data.text : '',
          }));
          break;
        case 'aiSources':
          if (data.sources) {
            try {
              const newSources = data.sources as Source[];
              setSources(newSources);
            } catch (error) {
              setSourceError(`There was an error when getting sources`);
            }
          }
          break;
        case 'inputError':
          console.log(`an error occured with inputs`);
          // TODO prompt user that an error occured and to adjust inputs?
          break;
        case 'serverError':
          console.log(`an error occured with the server`);
          // TODO Tell user server had an issue and maybe try again later
          break;
        case 'authError':
          nav('/signin');
          break;
        default:
          console.error('Unknown message type:', data.type);
          console.log(data);
      }
    };

    socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  };

  const sendQuery = () => {
    // const model = 'gpt-4';
    // const model = 'deepseek-chat';
    const model = 'claude';
    if (ws && ws.readyState === WebSocket.OPEN) {
      const payload = {
        modelType: model,
        query: chatData.query,
        educationLevel: chatSettings.educationLevel,
        detailLevel: chatSettings.detailLevel,
        threshold: chatSettings.threshold,
        selectedEntities: selectedEntities,
        chatId: chatData.chatId === -1 ? null : chatData.chatId,
      };
      setChatData((prev) => ({
        ...prev,
        query: '',
        botResponse: '',
        chatMessages: [
          ...prev.chatMessages,
          { role: 'user', text: chatData.query },
        ],
      }));
      ws.send(JSON.stringify(payload));
    }
  };

  const handleSubmit = () => {
    if (ws && ws.readyState === WebSocket.OPEN && chatData.query != '') {
      sendQuery();
    } else {
      startWebSocket(sendQuery); // Ensure WebSocket is open before sending the query
    }
    // sendQuery();
  };

  const getRecentHistory = async () => {
    const url = '/chat/history';
    setLoadingHistory(true);
    try {
      const jsonData = {
        maxHistorySize: 10,
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(jsonData),
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }

        throw new Error(`Network response was not ok`);
      }
      const json = await response.json();
      const history = JSON.parse(json) as TrimmedHistory[];
      setLoadingHistory(false);
      setRecentHistory(history);
    } catch (error) {
      setLoadingHistory(true);
      setHistoryError(true);
    }
  };

  const handleHistoryClicked = async (historyId: number) => {
    try {
      const url = `/chat/history/specific?chatHistoryId=${historyId}&maxHistorySize=20`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });

      if (!response.ok) {
        throw Error(
          `There was an error with the history network call, ${response.status}`
        );
      }

      const json: { chatRole: string; chatText: string }[] =
        await response.json();

      const chatMessages: ChatMessage[] = json.map((obj) => ({
        role: obj.chatRole,
        text: obj.chatText,
      }));
      chatMessages.reverse();
      setChatData({
        query: '',
        botResponse: '',
        chatMessages: chatMessages,
        chatId: historyId,
      });
    } catch (error) {
      console.log(`An error occurred:`);
      console.log(error);
    }
  };

  const handleNewChatClicked = async () => {
    setChatData({
      query: '',
      botResponse: '',
      chatMessages: [],
      chatId: -1,
    });
    setSources([]);
    await getRecentHistory();
  };

  // Get required background info
  useEffect(() => {
    getInitialEntities();
    getRecentHistory();
  }, []);

  // Set up the WebSocket on component mount
  useEffect(() => {
    startWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleHide = () => {
    setHide(!hide);
  };

  return (
    <SidePanelScaffold
      isLoggedIn={loginState.isLoggedIn}
      isAdmin={loginState.isAdmin}
      stickyTopBar={true}
      isExpanded={sideBarExpanded}
      showCollapseIcons={true}
      onExpandedToggle={toggleSideBarExpansion}
      sideBarChildren={
        <div>
          <div
            className="ChatSideBarNewChatButton"
            onClick={handleNewChatClicked}
          >
            <IoMdAdd /> <span>New Chat</span>
          </div>
          <div className="ChatSideBarHistoryHeaderRow">
            <label className="RecentHistoryHeaderText">Recent Chats</label>
          </div>

          <div className="ChatListContainer">
            {loadingHistory ? (
              <LoadingSpinner />
            ) : (
              <ul className="chat-list">
                {recentHistory.map((chat) => (
                  <li
                    key={chat.chatHistoryId}
                    onClick={() => handleHistoryClicked(chat.chatHistoryId)}
                  >
                    <FaRegMessage /> <span>{chat.text}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="show-more">
            <span
              onClick={() => {
                nav('/chat/history/search');
              }}
            >
              Show More
            </span>
          </div>
        </div>
      }
      mainPageChildren={
        <div className="primaryChatContainer">
          <div className="ChatPageMainWrapper">
            {sources.length > 0 && sourceError === '' ? (
              <div className="ChatPageSourceColumn">
                {sources.map((source, index) => (
                  <SourceCard source={source} key={index} />
                ))}
              </div>
            ) : (
              <>
                {sourceError != '' && (
                  <div className="ChatPageSourceColumn">
                    <HoveringCard isRounded={false} isBordered={false}>
                      <label className="ChatPageSourceErrorContainer">
                        {sourceError}
                      </label>
                    </HoveringCard>
                  </div>
                )}
              </>
            )}
            <div className="ChatPageChatArea">
              {chatData.chatMessages.length === 0 && !chatData.botResponse ? (
                // If there are no messages yet, show the default screen
                <div className="defaultScreen">
                  <img
                    src={'/video-search_Icon.png'}
                    alt="Logo"
                    className="logo"
                  />
                  <h4 className="defaultScreenName">Video Search AI</h4>
                </div>
              ) : (
                // Otherwise, show the scrolling chat
                <div className="ChatScrollArea">
                  {chatData.chatMessages.map((msg, i) => (
                    <div key={i} className={`chat-message ${msg.role}`}>
                      {msg.text}
                    </div>
                  ))}
                  {chatData.botResponse != '' && (
                    <div className="chat-message system">
                      {chatData.botResponse}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="ChatSearchContainer">
            <div className="ChatPageQuery">
              <LargeInputField
                value={chatData.query}
                label={'Query'}
                onValueChange={handleQueryChange}
                onEnterPress={handleSubmit}
              ></LargeInputField>
              <IoMdSend
                onClick={handleSubmit}
                className="ChatPageConfirmButton"
              />
            </div>
          </div>
          <BugButton parentPageUrl={'/chat'} token={token} nav={nav} />
          <FeedBackButton parentPageUrl={'/chat'} token={token} nav={nav} />
        </div>
      }
      topBarChildren={
        <>
          <MainLogo />
          <div className="gearIconDiv">
            <IoSettingsOutline
              className="gearIcon"
              onClick={() => {
                handleHide();
              }}
            />
            <div className={`ChatPageSetting ${hide ? 'hidden' : ''}`}>
              <div>
                <p>Education Level:</p>
                <InputField
                  value={chatSettings.educationLevel}
                  label={'Education Level'}
                  onValueChange={handleEducationLevelChange}
                ></InputField>
              </div>
              <div>
                <p> Detail Amount: </p>
                <DropDownMenu
                  choices={levels}
                  onSelect={(value) => handleDetailChange(value)}
                ></DropDownMenu>
              </div>
              <div>
                <p>Relevancy Threshold:</p>
                <NumberInputField
                  value={chatSettings.threshold}
                  label={'Threshold'}
                  onValueChange={handleThresholdChange}
                ></NumberInputField>
              </div>
            </div>
          </div>
        </>
      }
    ></SidePanelScaffold>
  );
}

export default ChatPage;
