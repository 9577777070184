import './Upload.page.css';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import DropDownMenu from '../../ui/components/DropDownMenu';
import SidePanelScaffold from '../../ui/pageScaffolds/SidePanelScaffold';
import AdminSideBarContent from '../../ui/panels/SideBars/AdminSideBarContent';
import MainLogo from '../../ui/components/MainLogo';
import TabForm from '../../ui/panels/Forms/TabForm';
import InputField from '../../ui/components/InputField';
import ExpandingTextArea from '../../ui/components/ExpandingTextArea';
import { isValidUrl } from '../../helpers/ValidationUtils';
import { error } from 'console';
import PageBlocker from '../../ui/components/PageBlocker';
import { LoginState } from '../../types/LoginState';
import BugButton from '../../ui/components/BugButton/BugButton';
import FeedBackButton from '../../ui/components/FeedBackButton/FeedBackButton';

interface YoutubeFormData {
  url: string;
  urlError: string;
  entityId: string;
}

interface AudioFormData {
  url: string | null;
  urlError: string;
  title: string;
  titleError: string;
  embedLink: string | null;
  embedLinkError: string;
  audioFile: File | null;
  audioFileError: string;
  formError: string;
  entityId: string;
}

interface TranscriptFormData {
  url: string | null;
  urlError: string;
  title: string;
  titleError: string;
  embedLink: string | null;
  embedLinkError: string;
  transcript: string;
  transcriptError: string;
  formError: string;
  entityId: string;
}

interface PresignedUrlResponse {
  presignedUrl: string;
  jobId: number;
}

interface EntityInfo {
  entityId: string;
  entityName: string;
}

function UploadPage(): JSX.Element {
  const token = localStorage.getItem('token'); // Retrieve the token
  const nav = useNavigate();
  const forms = ['Youtube', 'Audio', 'Transcript'];

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);
  const [entityInfo, setEntityInfo] = useState<EntityInfo[]>([]);
  const [loadingEntitiesError, setLoadingEntitiesError] =
    useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<EntityInfo | null>(null);

  const [youtubeFormData, setYoutubeFormData] = useState<YoutubeFormData>({
    url: '',
    urlError: '',
    entityId: '',
  });
  const [audioFormData, setAudioFormData] = useState<AudioFormData>({
    url: null,
    urlError: '',
    title: '',
    titleError: '',
    embedLink: null,
    embedLinkError: '',
    audioFile: null,
    audioFileError: '',
    formError: '',
    entityId: '',
  });
  const [transcriptFormData, setTranscriptFormData] =
    useState<TranscriptFormData>({
      url: null,
      urlError: '',
      title: '',
      titleError: '',
      embedLink: null,
      embedLinkError: '',
      transcript: '',
      transcriptError: '',
      formError: '',
      entityId: '',
    });

  const [loading, setLoading] = useState<boolean>(false);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [loginState, setLoginState] = useState<LoginState>({
    isLoggedIn: false,
    isAdmin: false,
  });

  const validateUserLogin = async () => {
    if (token) {
      const url = '/validate';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      if (!response.ok) {
        localStorage.removeItem('token');
        nav('/');
        return;
      }
      const json = await response.json();

      if (json.valid && json.valid === true) {
        setLoginState({ isLoggedIn: true, isAdmin: true });
      } else {
        localStorage.removeItem('token');
        nav('/');
      }
    }
  };

  useEffect(() => {
    validateUserLogin();
  }, []);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleYoutubeUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    setYoutubeFormData({
      ...youtubeFormData,
      url: event.target.value,
      urlError:
        isValidUrl(event.target.value) || event.target.value == ''
          ? ''
          : `Invalid URL provided`,
    });
  };

  const handleAudioUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    setAudioFormData({
      ...audioFormData,
      url: url == '' ? null : url,
      urlError: isValidUrl(url) ? '' : 'Invalid source url',
      formError: '',
    });
  };

  const handleAudioTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAudioFormData({
      ...audioFormData,
      title: event.target.value,
      titleError:
        event.target.value.length < 5 && event.target.value != ''
          ? 'Source title is too short.'
          : '',
      formError: '',
    });
  };

  const handleAudioEmbedLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    setAudioFormData({
      ...audioFormData,
      embedLink: url,
      embedLinkError:
        isValidUrl(url) || url == '' ? '' : 'Please provide a valid embed link',
      formError: '',
    });
  };

  const handleAudioFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type.startsWith('audio/')) {
        setAudioFormData({
          ...audioFormData,
          audioFile: file,
          audioFileError: '',
          formError: '',
        });
      } else {
        setAudioFormData({
          ...audioFormData,
          audioFileError: 'Please select a valid audio file',
          formError: '',
        });
      }
    }
  };

  const handleTranscriptUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    setTranscriptFormData({
      ...transcriptFormData,
      url: url == '' ? null : url,
      urlError: isValidUrl(url) ? '' : 'Invalid source url',
      formError: '',
    });
  };

  const handleTranscriptTitleChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setTranscriptFormData({
      ...transcriptFormData,
      title: event.target.value,
      titleError:
        event.target.value.length < 5 && event.target.value != ''
          ? 'Invalid title'
          : '',
      formError: '',
    });
  };

  const handleTranscriptEmbedLinkChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const url = event.target.value;
    setTranscriptFormData({
      ...transcriptFormData,
      embedLink: url,
      embedLinkError:
        isValidUrl(url) || url == '' ? '' : 'Please provide a valid embed link',
      formError: '',
    });
  };

  const handleTranscriptChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTranscriptFormData({
      ...transcriptFormData,
      transcript: event.target.value,
      transcriptError:
        event.target.value.length < 100 && event.target.value != ''
          ? 'Transcript is too short.'
          : '',
      formError: '',
    });
  };

  const handleTranscriptFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file && file.type === 'text/plain') {
      const reader = new FileReader();

      // Read the file content as text
      reader.onload = (e) => {
        const content = e.target?.result as string;
        setTranscriptFormData({ ...transcriptFormData, transcript: content });
      };

      // Handle any errors that might occur
      reader.onerror = () => {
        // TODO add an error note here
        console.error('Error reading file');
      };

      // Start reading the file
      reader.readAsText(file);

      event.target.value = '';
    } else {
      // TODO add a notification for user of invalid file
      // alert('Please upload a valid .txt file');
    }
  };

  const handleTranscriptFormClear = () => {
    setTranscriptFormData({
      url: null,
      urlError: '',
      title: '',
      titleError: '',
      embedLink: null,
      embedLinkError: '',
      transcript: '',
      transcriptError: '',
      formError: '',
      entityId: transcriptFormData.entityId,
    });
  };

  const handleAudioFormClear = () => {
    setAudioFormData({
      url: null,
      urlError: '',
      title: '',
      titleError: '',
      embedLink: null,
      embedLinkError: '',
      audioFile: null,
      audioFileError: '',
      formError: '',
      entityId: youtubeFormData.entityId,
    });
  };

  const handleYoutubeFormClear = () => {
    setYoutubeFormData({
      url: '',
      urlError: '',
      entityId: youtubeFormData.entityId,
    });
  };

  const handleYoutubeUpload = async () => {
    if (youtubeFormData.urlError != '') return;
    if (youtubeFormData.url == '') {
      setYoutubeFormData({
        ...youtubeFormData,
        urlError: `Please provide a Url`,
      });
      return;
    }
    const jsonData = JSON.stringify({
      url: youtubeFormData.url,
      entityId: youtubeFormData.entityId,
    });
    const url = '/admin/upload/youtubeurl';
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: jsonData,
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error(
          `An error ocurred when trying to upload your youtube url to be processed, try again later`
        );
      }
      handleYoutubeFormClear();
    } catch (error: any) {
      if (error.message) {
        setYoutubeFormData({
          ...youtubeFormData,
          urlError: error.message,
        });
      }
    }
    setLoading(false);
  };

  const handleAudioUpload = async () => {
    if (audioFormData.formError != '') return;
    if (audioFormData.audioFileError != '') {
      setAudioFormData({
        ...audioFormData,
        formError: `Please address errors.`,
      });
      return;
    }
    if (audioFormData.titleError != '') {
      setAudioFormData({
        ...audioFormData,
        formError: `Please address errors.`,
      });
      return;
    }
    if (audioFormData.embedLinkError != '') {
      setAudioFormData({
        ...audioFormData,
        formError: `Please address errors.`,
      });
      return;
    }
    if (audioFormData.title == '') {
      setAudioFormData({
        ...audioFormData,
        formError: `A title is required`,
      });
      return;
    }
    if (audioFormData.audioFile == null) {
      setAudioFormData({
        ...audioFormData,
        formError: `An audio file is required`,
      });
      return;
    }

    setLoading(true);
    let presignedUrlResponse: PresignedUrlResponse | null = null;
    try {
      presignedUrlResponse = await handleRequestUploadUrl(
        audioFormData.url,
        audioFormData.title,
        audioFormData.embedLink,
        audioFormData.entityId,
        audioFormData.audioFile.type
      );
    } catch (error: any) {
      if (error.message) {
        setAudioFormData({
          ...audioFormData,
          formError: error.message,
        });
      }
      setLoading(false);
      return;
    }
    let success: boolean = false;
    try {
      if (presignedUrlResponse == null) {
        throw Error(`We somehow had an invalid presigned url`);
      }
      success = await handleUploadAudioToUrl(
        presignedUrlResponse.presignedUrl,
        audioFormData.audioFile
      );
    } catch (error: any) {
      success = false;
    }
    try {
      await notifyBackend(success, presignedUrlResponse.jobId);
    } catch (error: any) {
      if (error.message) {
        setAudioFormData({
          ...audioFormData,
          formError: error.message,
        });
      }
      setLoading(false);
      return;
    }

    handleAudioFormClear();
    setLoading(false);
  };

  const handleRequestUploadUrl = async (
    url: string | null,
    title: string,
    embedLink: string | null,
    entityId: string,
    fileType: string
  ): Promise<PresignedUrlResponse> => {
    const jsonData = JSON.stringify({
      url: url,
      title: title,
      embedLink: embedLink,
      entityId: entityId,
      fileType: fileType,
    });
    const endpointUrl = '/admin/upload/audio/url';
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: jsonData,
    });
    if (!response.ok) {
      if (response.status === 401) {
        nav('/signin');
      }
      if (response.status === 400) {
        throw Error(`There may be an invalid value provided`);
      }
      console.log(response);
      throw Error(`Unexpected error, check logs for more detail`);
    }
    const result = JSON.parse(await response.json());
    const jobId = Number(result.jobId);
    const presignedUrl = result.presignedUrl;
    return { presignedUrl, jobId };
  };

  const handleUploadAudioToUrl = async (
    endpointUrl: string,
    audioFile: File
  ) => {
    const uploadResponse = await fetch(endpointUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': audioFile.type, // Set the correct MIME type
      },
      body: audioFile,
    });

    return uploadResponse.ok;
  };

  const notifyBackend = async (success: boolean, jobId: number) => {
    const endpointUrl = '/admin/upload/audio/validate';
    const jsonData = JSON.stringify({
      success: success,
      jobId: jobId,
    });
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: jsonData,
    });
    if (success && response.status == 404) {
      throw Error(
        `We were able to upload but were unable to find the file when it got uploaded`
      );
    }
    if (success && response.status == 200) {
      return;
    }
    if (!success && response.status == 200) {
      throw Error(`We were unable to upload the file!`);
    }
    if (response.status == 500) {
      throw Error(
        `There was a critical server error, you may want to try later`
      );
    }
    throw Error(
      `How in the hell did we get here, this shouldn't be possible right?`
    );
  };

  const handleTranscriptUpload = async () => {
    if (transcriptFormData.formError != '') return;
    if (transcriptFormData.titleError != '') {
      setTranscriptFormData({
        ...transcriptFormData,
        formError: 'Please address errors.',
      });
      return;
    }
    if (transcriptFormData.transcriptError != '') {
      setTranscriptFormData({
        ...transcriptFormData,
        formError: 'Please address errors.',
      });
      return;
    }
    if (transcriptFormData.urlError != '') {
      setTranscriptFormData({
        ...transcriptFormData,
        formError: 'Please address errors.',
      });
      return;
    }
    if (transcriptFormData.embedLinkError != '') {
      setTranscriptFormData({
        ...transcriptFormData,
        formError: 'Please address errors.',
      });
      return;
    }
    if (transcriptFormData.transcript == '') {
      setTranscriptFormData({
        ...transcriptFormData,
        formError: 'A transcript is required',
      });
      return;
    }
    if (transcriptFormData.title == '') {
      setTranscriptFormData({
        ...transcriptFormData,
        formError: 'A title is required',
      });
      return;
    }

    const jsonData = JSON.stringify({
      url: transcriptFormData.url,
      title: transcriptFormData.title,
      embedLink: transcriptFormData.embedLink,
      transcript: transcriptFormData.transcript,
      entityId: transcriptFormData.entityId,
    });
    const url = '/admin/upload/transcript';
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: jsonData,
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw Error(
          `There was an issue when trying to send the transcript to the server`
        );
      }
      handleTranscriptFormClear();
    } catch (error: any) {
      if (error.message) {
        setTranscriptFormData({
          ...transcriptFormData,
          formError: error.message,
        });
      }
    }
    setLoading(false);
  };

  const requesteEntityData = async () => {
    const url = `/admin/entities`;
    try {
      setLoadingEntities(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const jsonResponse = await response.json();
      const info: EntityInfo[] = JSON.parse(jsonResponse) as EntityInfo[];

      if (info.length === 0) {
        console.log(`Are you an admin?`);
        throw Error(`Admin is admin of 0 entities`);
      }

      setEntityInfo(info);
      setSelectedEntity(info[0]);
      setYoutubeFormData({ ...youtubeFormData, entityId: info[0].entityId });
      setTranscriptFormData({
        ...transcriptFormData,
        entityId: info[0].entityId,
      });
      setAudioFormData({ ...audioFormData, entityId: info[0].entityId });
      setLoadingEntities(false);
      setLoadingEntitiesError(false);
    } catch (error) {
      setLoadingEntities(false);
      setLoadingEntitiesError(true);
      console.log(`An error occured when trying to get entity data`);
      console.log(error);
    }
  };

  const handleEntitySelected = (entityName: string) => {
    const selectedEntity = entityInfo.find(
      (item) => item.entityName === entityName
    );
    if (selectedEntity) {
      setYoutubeFormData({
        ...youtubeFormData,
        entityId: selectedEntity.entityId,
      });
      setTranscriptFormData({
        ...transcriptFormData,
        entityId: selectedEntity.entityId,
      });
      setAudioFormData({ ...audioFormData, entityId: selectedEntity.entityId });
      setSelectedEntity(selectedEntity);
    }
  };

  useEffect(() => {
    if (!loadingEntities && !loadingEntitiesError && entityInfo.length == 0) {
      requesteEntityData();
    }
  }, [loadingEntitiesError]);

  let mainPageContent: JSX.Element;

  if (loadingEntities) {
    mainPageContent = <LoadingSpinner />;
  }

  if (selectedEntity == null && entityInfo.length > 0) {
    mainPageContent = (
      <div>
        <DropDownMenu
          choices={entityInfo.map((item) => item.entityName)}
          onSelect={handleEntitySelected}
        ></DropDownMenu>
      </div>
    );
  } else {
    mainPageContent = (
      <div className="UploadPage">
        <DropDownMenu
          choices={entityInfo.map((item) => item.entityName)}
          onSelect={handleEntitySelected}
        ></DropDownMenu>
        <TabForm
          tabContent={[
            {
              tabLabel: forms[0],
              tabChildren: (
                <>
                  <label>Url is required</label>
                  <div className="UploadFormRow">
                    <span>URL</span>
                  </div>
                  <div className="UploadFormRow">
                    <InputField
                      value={youtubeFormData.url}
                      label={'Youtube Url'}
                      onValueChange={handleYoutubeUrlChange}
                    />
                  </div>
                  {youtubeFormData.urlError != '' && (
                    <div className="UploadFormRow">
                      <span>{youtubeFormData.urlError}</span>
                    </div>
                  )}
                  <div className="UploadFormSubmitRow">
                    <button
                      className="UploadFormButton"
                      onClick={handleYoutubeFormClear}
                    >
                      Clear
                    </button>
                    <button
                      className="UploadFormButton"
                      onClick={handleYoutubeUpload}
                    >
                      Submit
                    </button>
                  </div>
                </>
              ),
            },
            {
              tabLabel: forms[1],
              tabChildren: (
                <>
                  <label>Audio File and Title are required</label>
                  <div className="UploadFormRow">
                    <span>URL</span>
                  </div>
                  <div className="UploadFormRow">
                    <InputField
                      value={audioFormData.url ? audioFormData.url : ''}
                      label={'Url of source'}
                      onValueChange={handleAudioUrlChange}
                    />
                  </div>
                  {audioFormData.urlError != '' && (
                    <div className="UploadFormRow">
                      <span>{audioFormData.urlError}</span>
                    </div>
                  )}
                  <div className="UploadFormRow">
                    <span>Embed Link</span>
                  </div>
                  <div className="UploadFormRow">
                    <InputField
                      value={
                        audioFormData.embedLink ? audioFormData.embedLink : ''
                      }
                      label={'Embed link'}
                      onValueChange={handleAudioEmbedLinkChange}
                    />
                  </div>
                  <div className="UploadFormRow">
                    <span>Source Title</span>
                  </div>
                  <div className="UploadFormRow">
                    <InputField
                      value={audioFormData.title}
                      label={'Source title'}
                      onValueChange={handleAudioTitleChange}
                    />
                  </div>
                  {audioFormData.titleError != '' && (
                    <div className="UploadFormRow">
                      <span>{audioFormData.titleError}</span>
                    </div>
                  )}
                  <div className="UploadFormRow">
                    <input
                      type="file"
                      accept="audio/*"
                      onChange={handleAudioFileChange}
                    />
                  </div>
                  {audioFormData.audioFileError != '' && (
                    <div className="UploadFormRow">
                      <span>{audioFormData.audioFileError}</span>
                    </div>
                  )}
                  {audioFormData.formError != '' && (
                    <div className="UploadFormRow">
                      <span>{audioFormData.formError}</span>
                    </div>
                  )}
                  <div className="UploadFormSubmitRow">
                    <button
                      className="UploadFormButton"
                      onClick={handleAudioFormClear}
                    >
                      Clear
                    </button>
                    <button
                      className="UploadFormButton"
                      onClick={handleAudioUpload}
                    >
                      Submit
                    </button>
                  </div>
                </>
              ),
            },
            {
              tabLabel: forms[2],
              tabChildren: (
                <>
                  <label>Title and Transcript are Required</label>
                  <div className="UploadFormRow">
                    <span>URL</span>
                  </div>
                  <div className="UploadFormRow">
                    <InputField
                      value={
                        transcriptFormData.url ? transcriptFormData.url : ''
                      }
                      label={'Url of source'}
                      onValueChange={handleTranscriptUrlChange}
                    />
                  </div>
                  {transcriptFormData.urlError != '' && (
                    <div className="UploadFormRow">
                      <span>{transcriptFormData.urlError}</span>
                    </div>
                  )}
                  <div className="UploadFormRow">
                    <span>Embed Link</span>
                  </div>
                  <div className="UploadFormRow">
                    <InputField
                      value={
                        transcriptFormData.embedLink
                          ? transcriptFormData.embedLink
                          : ''
                      }
                      label={'Embed link'}
                      onValueChange={handleTranscriptEmbedLinkChange}
                    />
                  </div>
                  <div className="UploadFormRow">
                    <span>Source Title</span>
                  </div>
                  <div className="UploadFormRow">
                    <InputField
                      value={transcriptFormData.title}
                      label={'Source title'}
                      onValueChange={handleTranscriptTitleChange}
                    />
                  </div>
                  {transcriptFormData.titleError != '' && (
                    <div className="UploadFormRow">
                      <span>{transcriptFormData.titleError}</span>
                    </div>
                  )}
                  <div className="UploadFormRow">
                    <ExpandingTextArea
                      maxLines={20}
                      value={transcriptFormData.transcript}
                      label={'Transcript content:'}
                      onValueChange={handleTranscriptChange}
                    />
                  </div>
                  <div className="UploadFormRow">
                    <label
                      htmlFor="UploadTranscriptFileUploadButton"
                      className="UploadTranscriptFileLabel"
                    >
                      Choose a File
                    </label>
                    <input
                      id="UploadTranscriptFileUploadButton"
                      className="UploadTranscriptInput"
                      type="file"
                      accept=".txt"
                      onChange={handleTranscriptFileChange}
                    />
                  </div>
                  {transcriptFormData.transcriptError != '' && (
                    <div className="UploadFormRow">
                      <span>{transcriptFormData.transcriptError}</span>
                    </div>
                  )}
                  {transcriptFormData.formError != '' && (
                    <div className="UploadFormRow">
                      <span>{transcriptFormData.formError}</span>
                    </div>
                  )}
                  <div className="UploadFormSubmitRow">
                    <button
                      className="UploadFormButton"
                      onClick={handleTranscriptFormClear}
                    >
                      Clear
                    </button>
                    <button
                      className="UploadFormButton"
                      onClick={handleTranscriptUpload}
                    >
                      Submit
                    </button>
                  </div>
                </>
              ),
            },
            {
              tabLabel: 'Instructions',
              tabChildren: (
                <>
                  <p>
                    Each form has the required information of what is required
                    located at the top of the form. If you would like to provide
                    the other information it will just allow us to improve the
                    general user experience.
                  </p>
                  <p>
                    Note: if you do not provide a url source for your users then
                    they may have a hard time re-referencing this information.
                  </p>
                  <p>
                    Note: Youtube url uploads are not currently processed
                    automaticaly and may take longer to get through our system.
                  </p>
                </>
              ),
            },
          ]}
          onTabSelect={() => {}}
        />
        {loading && (
          <PageBlocker
            isDismissable={false}
            onDismiss={() => {}}
            children={<LoadingSpinner />}
          ></PageBlocker>
        )}
      </div>
    );
  }

  return (
    <SidePanelScaffold
      isLoggedIn={loginState.isLoggedIn}
      isAdmin={loginState.isAdmin}
      stickyTopBar={true}
      isExpanded={isExpanded}
      showCollapseIcons={true}
      onExpandedToggle={handleExpandToggle}
      mainPageChildren={
        <>
          {mainPageContent}
          {loginState.isLoggedIn && (
            <>
              <BugButton
                parentPageUrl={'/admin/upload'}
                token={token}
                nav={nav}
              />
              <FeedBackButton
                parentPageUrl={'/admin/upload'}
                token={token}
                nav={nav}
              />
            </>
          )}
        </>
      }
      sideBarChildren={<AdminSideBarContent nav={nav} />}
      topBarChildren={
        <>
          <MainLogo />
          <div />
        </>
      }
    ></SidePanelScaffold>
  );
}

export default UploadPage;
