import { useEffect, useState } from 'react';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import TranscriptTable from '../../ui/panels/Tables/TranscriptTable';
import StudyTable from '../../ui/panels/Tables/StudyTable';
import StudyForm from '../../ui/panels/Forms/StudyForm';
import Signout from '../../pages/User/SignOut/SignOut.page';
import { IoLogoElectron } from 'react-icons/io5';
import './Admin.page.css';
import DropDownMenu from '../../ui/components/DropDownMenu';
import { useNavigate } from 'react-router-dom';
import MainLogo from '../../ui/components/MainLogo';
import SidePanelScaffold from '../../ui/pageScaffolds/SidePanelScaffold';
import AdminSideBarContent from '../../ui/panels/SideBars/AdminSideBarContent';
import FeedBackButton from '../../ui/components/FeedBackButton/FeedBackButton';
import BugButton from '../../ui/components/BugButton/BugButton';
import { LoginState } from '../../types/LoginState';

const isMock = process.env.REACT_APP_MOCK === 'true';

interface EntityInfo {
  entityId: string;
  entityName: string;
}

function AdminPage(): JSX.Element {
  const nav = useNavigate();
  const token = localStorage.getItem('token'); // Retrieve the token

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);
  const [entityInfo, setEntityInfo] = useState<EntityInfo[]>([]);
  const [loadingEntitiesError, setLoadingEntitiesError] =
    useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<EntityInfo | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [loginState, setLoginState] = useState<LoginState>({
    isLoggedIn: false,
    isAdmin: false,
  });

  const validateUserLogin = async () => {
    if (token) {
      const url = '/validate';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      if (!response.ok) {
        localStorage.removeItem('token');
        nav('/');
        return;
      }
      const json = await response.json();

      if (json.valid && json.valid === true) {
        setLoginState({ isLoggedIn: true, isAdmin: true });
      } else {
        localStorage.removeItem('token');
        nav('/');
      }
    }
  };

  useEffect(() => {
    validateUserLogin();
  }, []);

  const requesteEntityData = async () => {
    const mockEndpoint = isMock ? '/mock' : '';
    const url = `/admin/entities${mockEndpoint}`;
    try {
      setLoadingEntities(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const jsonResponse = await response.json();
      const info: EntityInfo[] = JSON.parse(jsonResponse) as EntityInfo[];

      if (info.length === 0) {
        console.log(`Are you an admin?`);
        throw Error(`Admin is admin of 0 entities`);
      }

      setEntityInfo(info);
      setSelectedEntity(info[0]);
      setLoadingEntities(false);
      setLoadingEntitiesError(false);
    } catch (error) {
      setLoadingEntities(false);
      setLoadingEntitiesError(true);
      console.log(`An error occured when trying to get entity data`);
      console.log(error);
    }
  };

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    requesteEntityData();
  }, []);

  if (loadingEntities) {
    return <LoadingSpinner />;
  }

  if (loadingEntitiesError) {
    return (
      <p>
        If your seeing this something bad happened, maybe the server is down?
      </p>
    );
  }

  return (
    <SidePanelScaffold
      isAdmin={loginState.isAdmin}
      isLoggedIn={loginState.isLoggedIn}
      stickyTopBar={true}
      isExpanded={isExpanded}
      showCollapseIcons={true}
      onExpandedToggle={handleExpandToggle}
      sideBarChildren={<AdminSideBarContent nav={nav} />}
      mainPageChildren={
        <div>
          <BugButton parentPageUrl={'/admin'} token={token} nav={nav} />
          <FeedBackButton parentPageUrl={'/admin'} token={token} nav={nav} />
        </div>
      }
    ></SidePanelScaffold>
  );
}

export default AdminPage;
