import React, { useEffect, useState } from 'react';
import './Home.page.css';
import { useNavigate } from 'react-router-dom';
import MainTopBar from '../../ui/panels/TopBars/MainTopBar';
import HoveringCard from '../../ui/components/HoveringCard';
import PrimaryFooter from '../../ui/components/PrimaryFooter';
import heroImage from '../../images/illustration-grp.png';
import capsule from '../../images/redblue-capsule.png';
import trail from '../../images/wave-trail.png';
import question from '../../images/question.png';
import idea from '../../images/idea.png';
import files from '../../images/files.png';
import search from '../../images/search.png';
import { LoginState } from '../../types/LoginState';
import FeedBackButton from '../../ui/components/FeedBackButton/FeedBackButton';
import BugButton from '../../ui/components/BugButton/BugButton';

function HomePage() {
  const nav = useNavigate();
  const token = localStorage.getItem('token');
  const [loginState, setLoginState] = useState<LoginState>({
    isLoggedIn: false,
    isAdmin: false,
  });

  const validateUserLogin = async () => {
    if (token) {
      const url = '/validate';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      if (!response.ok) {
        setLoginState({ isLoggedIn: false, isAdmin: false });
        return;
      }
      const json = await response.json();

      if (json.valid && json.valid === true) {
        setLoginState({ isLoggedIn: true, isAdmin: true });
      } else {
        setLoginState({ isLoggedIn: true, isAdmin: false });
      }
    }
  };

  useEffect(() => {
    validateUserLogin();
  }, []);

  return (
    <div className="HomePageContainer">
      <div className="HomePageSplashGradient one"></div>
      <div className="HomePageSplashGradient two"></div>
      <MainTopBar
        isLoggedIn={loginState.isLoggedIn}
        isSticky={true}
        showAdminButton={loginState.isAdmin}
      />
      <div className="HomePageContentContainer">
        <div className="HomePageHeroRow">
          <div className="HomePageHeroSpacer" />
          <div className="HomePageHeroSpacer" />
          <div className="HomePageHeroTextColumn">
            <span className="HomePageHeroSectionText">
              Find info easily with AI
            </span>
            <p className="HomePageHeroParagraphText">
              In a world full of information, getting the answers you need
              shouldn't be a hassle. We make it easy—quick, accurate, and always
              backed by real sources.
            </p>
            <button
              className="HomePageHeroButton"
              onClick={() => {
                nav('/chat');
              }}
            >
              Start Chat
            </button>
          </div>
          <div className="HomePageHeroImageColumn">
            <div className="HomePageHeroImageTextOneContainer">
              <p className="HomePageHeroImageText">
                Do you have any studies on vitamin D?
              </p>
            </div>
            <div className="HomePageHeroImageBox" />
            <div className="HomePageHeroImageImageContainer" />
            <img
              src={heroImage}
              alt="Illustration"
              className="HomePageHeroImageImageContainer"
            />
            <div className="HomePageHeroImageTextTwoContainer">
              <p className="HomePageHeroImageText">
                Find some videos on Vitamin D and its related effects.
              </p>
            </div>
            <img
              src={capsule}
              alt="Illustration"
              className="HomePageHeroImageCapsule"
            />
          </div>
          <div className="HomePageHeroSpacer" />
        </div>
        <span className="HomePageJoinSectionText">How it Works</span>
        <div className="HomePageHowItWorksRow">
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#FFDD00' }}
                >
                  <img
                    src={question}
                    alt="Illustration"
                    className="HomePageHowIconQuestion"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 1</div>
                <div className="HomePageHowDescriptionText">
                  Ask a question on a topic
                </div>
              </div>
            </div>
          </HoveringCard>
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#00F25C' }}
                >
                  <img
                    src={search}
                    alt="Illustration"
                    className="HomePageHowIconSearch"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 2</div>
                <div className="HomePageHowDescriptionText">
                  If we have information in the topic, the AI will find it.
                </div>
              </div>
            </div>
          </HoveringCard>
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#886DF0' }}
                >
                  <img
                    src={idea}
                    alt="Illustration"
                    className="HomePageHowIconIdea"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 3</div>
                <div className="HomePageHowDescriptionText">
                  It forms an informed answer and cites the sources for you.
                </div>
              </div>
            </div>
          </HoveringCard>
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#FF0000' }}
                >
                  <img
                    src={files}
                    alt="Illustration"
                    className="HomePageHowIconFiles"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 4</div>
                <div className="HomePageHowDescriptionText">
                  It then provides the sources to you for further research.
                </div>
              </div>
            </div>
          </HoveringCard>
        </div>
        <div className="HomePageJoinRow">
          <div className="HomePageJoinText">Ready to get started?</div>
          <img src={trail} alt="Illustration" className="HomePageJoinTrail" />
          <button
            className="HomePageJoinButton"
            onClick={() => {
              nav('/signup');
            }}
          >
            Sign-up Now
          </button>
        </div>
        <PrimaryFooter />
      </div>
      {loginState.isLoggedIn && (
        <>
          <BugButton parentPageUrl={'/'} token={token} nav={nav} />
          <FeedBackButton parentPageUrl={'/'} token={token} nav={nav} />
        </>
      )}
    </div>
  );
}

export default HomePage;
