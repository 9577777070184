import React from 'react';
import MainLogo from '../MainLogo';
import { useNavigate } from 'react-router-dom';
import './PrimaryFooter.css';

function PrimaryFooter(): JSX.Element {
  const nav = useNavigate();
  return (
    <div className="PrimaryFooterContainer">
      <div className="PrimaryFooterContentContainer">
        <div className="PrimaryFooterContainerDisclaimerColumn">
          <MainLogo />
          <p className="PrimaryFooterContainerDisclaimerText">
            We are committed to getting you the best possible answers and
            information! Feel free to reach out and let us know any issues or
            feedback.
          </p>
        </div>
        <div className="PrimaryFooterSpacer" />
        <div className="PrimaryFooterContainerNavigationColumn">
          <span
            className="PrimaryFooterNavigationText"
            onClick={() => {
              nav('/');
            }}
          >
            Home
          </span>
          <span
            className="PrimaryFooterNavigationText"
            onClick={() => {
              nav('/joinus');
            }}
          >
            Join Us
          </span>
          <span
            className="PrimaryFooterNavigationText"
            onClick={() => {
              nav('/aboutus');
            }}
          >
            About Us
          </span>
        </div>
        <div className="PrimaryFooterContainerNavigationColumn">
          <span
            className="PrimaryFooterNavigationText"
            onClick={() => {
              nav('/terms');
            }}
          >
            Terms of Use
          </span>
          <span
            className="PrimaryFooterNavigationText"
            onClick={() => {
              nav('/privacy');
            }}
          >
            Privacy Policy
          </span>
        </div>
        <div className="PrimaryFooterContainerContactUsColumn">
          <span style={{ fontWeight: 'bold' }}>Contact at:</span>
          <p>Email: support@Sydmed.com</p>
          <p>Phone: 123 456 7890</p>
        </div>
      </div>

      <div className="PrimaryFooterBreakLine" />
      <p>Copyright © video-search.ai</p>
    </div>
  );
}

export default PrimaryFooter;
