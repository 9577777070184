// import React from 'react';
// import './SourceCard.css';
// import HoveringCard from '../HoveringCard';
// import { Source } from '../../../types/Source';

// export interface Props {
//   source: Source;
// }

// function SourceCard({ source }: Props): JSX.Element {
//   return (
//     <HoveringCard isRounded={false} isBordered={false}>
//       <div className="SourceCardContainer">
//         <a
//           href={source.videoURL}
//           target="_blank"
//           rel="noopener noreferrer"
//           className="SourceCardLinkText"
//         >
//           {source.videoTitle}
//         </a>
//         <div className="SourceCardRelevancyContainer">
//           <h4 className="SourceCardRelevancyScore">{source.relevancy}%</h4>
//           <div className="SourceCardRelevanceBarContainer">
//             <div
//               className="SourceCardRelevanceBar"
//               style={{ width: `${source.relevancy}%` }}
//             />
//           </div>
//         </div>
//       </div>
//     </HoveringCard>
//   );
// }

// export default SourceCard;

import React from 'react';
import './SourceCard.css';
import HoveringCard from '../HoveringCard';
import { Source } from '../../../types/Source';

export interface Props {
  source: Source;
}

function SourceCard({ source }: Props): JSX.Element {
  // Function to extract YouTube video ID
  const getYouTubeEmbedUrl = (url: string): string | null => {
    try {
      const parsedUrl = new URL(url);
      if (
        parsedUrl.hostname.includes('youtube.com') ||
        parsedUrl.hostname.includes('youtu.be')
      ) {
        const videoId = parsedUrl.hostname.includes('youtu.be')
          ? parsedUrl.pathname.substring(1) // Extract video ID from youtu.be link
          : parsedUrl.searchParams.get('v'); // Extract video ID from youtube.com/watch?v= link

        return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
      }
    } catch (error) {
      console.error('Invalid YouTube URL:', url);
    }
    return null;
  };

  const embedUrl = getYouTubeEmbedUrl(source.videoURL);

  const percentage = Math.round(
    Math.min(100, Math.max(0, Number(source.relevancy)))
  );

  // Calculate stroke for the semicircle
  const radius = 40; // Radius of the semicircle
  const circumference = Math.PI * radius; // Circumference of the semicircle
  const strokeDashoffset = circumference * (1 - percentage / 100); // Offset for p

  return (
    <HoveringCard isRounded={true} isBordered={true}>
      <div className="SourceCardContainer">
        {/* When we have a placeholder image */}
        {embedUrl && (
          <div className="SourceCardEmbedContainer">
            <iframe src={embedUrl} allowFullScreen title={source.videoTitle} />
          </div>
        )}
        <a
          href={source.videoURL}
          target="_blank"
          rel="noopener noreferrer"
          className="SourceCardLinkText"
        >
          {source.videoTitle}
        </a>
        <div className="SourceCardRelevancyContainer">
          <svg width="100" height="60" viewBox="0 0 100 50">
            {/* Background semicircle */}
            <path
              d="M 10 50 A 40 40 0 0 1 90 50"
              stroke="#e0e0e0"
              strokeWidth="10"
              fill="none"
              strokeLinecap="round"
            />
            {/* Filled semicircle based on percentage */}
            <path
              d="M 10 50 A 40 40 0 0 1 90 50"
              stroke="#277ee5"
              strokeWidth="10"
              fill="none"
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap="round"
            />
            {/* Score in the middle */}
            <text
              x="50"
              y="45"
              textAnchor="middle"
              className="SourceCardRelevancyScore"
            >
              {percentage}
            </text>
          </svg>
        </div>
        {/* Conditionally render YouTube embed */}
      </div>
    </HoveringCard>
  );
}

export default SourceCard;
