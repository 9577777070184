import React, { ChangeEvent, useEffect, useState } from 'react';
import './SidePanelScaffold.css';
import '../../Shared.css';
import GenericSideBar from '../../panels/SideBars/GenericSideBar';
import { FaBars } from 'react-icons/fa';
import GenericTopBar from '../../panels/TopBars/GenericTopBar';
import MainTopBar from '../../panels/TopBars/MainTopBar';

interface Props {
  isLoggedIn: boolean;
  isAdmin: boolean;
  stickyTopBar: boolean;
  isExpanded: boolean;
  showCollapseIcons: boolean;
  onExpandedToggle: () => void;
  topBarChildren?: React.ReactNode;
  sideBarChildren?: React.ReactNode;
  mainPageChildren?: React.ReactNode;
}

function SidePanelScaffoldPage({
  isLoggedIn,
  isAdmin,
  stickyTopBar,
  isExpanded,
  showCollapseIcons,
  onExpandedToggle,
  topBarChildren,
  sideBarChildren,
  mainPageChildren,
}: Props): JSX.Element {
  return (
    <div className="SidePanelScaffoldContainer">
      <GenericSideBar transition={true} rounded={true} isExpanded={isExpanded}>
        {showCollapseIcons && (
          <FaBars
            onClick={onExpandedToggle}
            className="SidePanelScaffoldToggleButton"
          />
        )}
        {sideBarChildren}
      </GenericSideBar>
      <div className="SidePanelScaffoldPageContainer">
        <div className="SidePanelScaffoldPageTopBarContainer">
          <MainTopBar
            isSticky={stickyTopBar}
            isLoggedIn={isLoggedIn}
            showAdminButton={isAdmin}
            additionalLeftChildren={
              <>
                {!isExpanded && showCollapseIcons && (
                  <FaBars
                    onClick={onExpandedToggle}
                    className="SidePanelScaffoldToggleButton"
                  />
                )}
              </>
            }
          />
        </div>
        {mainPageChildren}
      </div>
    </div>
  );
}

export default SidePanelScaffoldPage;
